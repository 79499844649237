/** @jsxImportSource @emotion/react */
import { Dialog, Box, Typography, TextField, Button, Container, CircularProgress, Backdrop, Alert, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ICircuitT1plusDetail } from "../../Api/types";
import { useState, useEffect, useRef } from "react";
import { AppDispatch } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../store/store';
import { setIsFulfilled, updateCircuitThunk, setCircuitDetailList } from "../../Slices/T1Plus/circuitT1PlusSlice";
import { useNavigate } from 'react-router-dom';
import { toggleBtnsStyle } from '../../Styles/styling-variables';
import ErrorBackdropComponent from "../ErrorBackdropComponent";
import BackdropComponent from "../BackdropComponent";
import DateInputComponent from "../DateInputComponent";


interface CircuitsT1PlusProps {
    isDialogOpen: boolean;
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CircuitsT1plus ({ isDialogOpen, setIsDialogOpen,}: CircuitsT1PlusProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const dispatchThunk = useDispatch<AppDispatch>();
    const dispatch = useDispatch();
    const [bottomMarginContainer, setBottomMarginContainer] = useState<string>();
    const fixedContainerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const { selectedCircuit, isLoading, isRejected, isFulfilled, empId, contractType } = useSelector((state: RootState) => ({
        selectedCircuit: state.circuit.selectedCircuit,
        isLoading: state.circuit.isLoading,
        isRejected: state.circuit.isRejected,
        isFulfilled: state.circuit.isFulfilled,
        empId: state.user.empId,
        contractType: state.user.contracttype
    }));
    
    useEffect(() => {
        if(fixedContainerRef.current)  { 
            const fixedContainerHeight = fixedContainerRef.current.offsetHeight;
            setBottomMarginContainer(`${fixedContainerHeight + 60}px`); 
        }        
    }, [fixedContainerRef.current]);

    const [responseMeasurement, setResponseMeasurement] = useState<number | null>(selectedCircuit?.respuestaDiferencial ?? null);
    const [measurementDiff, setMeasurementDiff] = useState<number | null>(selectedCircuit?.medidaDiferencial ?? null);
    const [insulation, setInsulation] = useState<number | null>(selectedCircuit?.aislamiento ?? null);
    const [fvervis, setFvervis] = useState<Date>(selectedCircuit?.fvervis ? new Date(selectedCircuit.fvervis) : new Date());
    const handleFvervisChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const dateString = e.target.value;
        const data = new Date(dateString);
        setFvervis(data);
    }
    const [isInspected, setIsInspected] = useState<string>(selectedCircuit?.isInspected ?? 'N');
    const handleIsInspected = (e: React.ChangeEvent<any>, value: string | null) => {
        if (value != null || value != undefined) {
            setIsInspected(value);
        } else {
            setError("Seleccioneu un valor");
        }
    }

    const [isActive, setIsActive] = useState<boolean | null>(selectedCircuit?.circuitoActivo ?? null);
    const handleIsActive = (e: React.ChangeEvent<any>, value: boolean | null) => {
        if(value !== null) {
            setIsActive(value);
        }        
    }

    const [isSormon, setIsSormon] = useState<boolean | null>(selectedCircuit?.sormon ?? null);
    const handleIsSormon= (e: React.ChangeEvent<any>, value: boolean | null) => {
        if(value !== null) {
            setIsSormon(value);
        }        
    }

    const [isSortReg, setIsSortReg] = useState<boolean | null>(selectedCircuit?.xcirafereg ?? null);
    const handleIsSortReg= (e: React.ChangeEvent<any>, value: boolean | null) => {
        if(value !== null) {
            setIsSortReg(value);
        }        
    }

    const [descriptioncircuitValue, setDescriptioncircuit] = useState<string>(selectedCircuit?.descripcion ?? '');

    
    

    const handleSaveClick = async () => {
        if(selectedCircuit?.rid && isActive !== null && isInspected ==='Y' && isSormon!==null && isSortReg!==null) {
            console.log ( "Actualitzo");
            const updatedCircuit = {
                
                rid: selectedCircuit.rid,
                cenmando: selectedCircuit.cenmando,              
                circuitName: selectedCircuit.circuitName,
                fvervis: fvervis,
                respuestaDiferencial: responseMeasurement ?? null,
                medidaDiferencial: measurementDiff ?? null,  
                aislamiento: insulation ?? null,
                circuitoActivo: isActive,              
                isInspected: isInspected,
                empId: empId,
                sormon:isSormon,
                xcirafereg:isSortReg,
                descripcion: descriptioncircuitValue
            }
            if(updatedCircuit && contractType) {
                console.log ( 'contracte' + contractType.toString());
                await dispatchThunk(updateCircuitThunk({updatedCircuit, contractType}));
            }
        } else if(isActive === null) {
            setError("Indiqueu si el circuit és actiu o inactiu");
        }
         else if(isInspected === 'N') {
            setError("Indiqueu que s'ha revisat el circuit");
        }
    }

    const handleCancelBtnClick = () => {
        setIsDialogOpen(false);
    }

    useEffect(() => {
        if (isLoading) {
            setIsOpen(true);
        }
        if (isFulfilled) {
            setIsOpen(true);
            setTimeout(() => {
                dispatch(setIsFulfilled(false));
                setIsOpen(false);
                setIsDialogOpen(false);
            }, 3000);
        }
        if (isRejected) {
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
            }, 3000);
        }
        if(error){
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
                setError(null);
            }, 3000);
        }
    }, [dispatch, isFulfilled, isRejected, error])

    useEffect(() => {
        setResponseMeasurement(selectedCircuit?.respuestaDiferencial ?? null);
        setMeasurementDiff(selectedCircuit?.medidaDiferencial ?? null);
        setInsulation(selectedCircuit?.aislamiento ?? null);
        setFvervis(selectedCircuit?.fvervis ? new Date(selectedCircuit.fvervis) : new Date());
        setIsActive(selectedCircuit?.circuitoActivo ?? null);
        setIsSormon(selectedCircuit?.sormon ?? null);
        setIsSortReg(selectedCircuit?.xcirafereg ?? null);
        setDescriptioncircuit(selectedCircuit?.descripcion ?? '');
        setIsInspected(selectedCircuit?.isInspected ?? 'N');
        if(!selectedCircuit?.rid) {
            setIsDialogOpen(false);           
        }
    }, [selectedCircuit])
    


    return(
        <Dialog open={isDialogOpen} fullScreen sx={{height:'100vh'}}>
            <Container sx={{ marginTop: 'calc(1vw + 15px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: 'calc(1vw + 6px)', marginBottom: bottomMarginContainer}}>
                <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignItems:'start'}} >
                    <Typography variant='subtitle1' fontWeight={'medium'} >ID Circuit</Typography>
                    <TextField id='rid' type='number' value={selectedCircuit?.rid} contentEditable={false} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} inputProps={{ }} />
                </Box>
                <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignItems:'start'}} >
                    <Typography variant='subtitle1' fontWeight={'medium'} >CENMANDO</Typography>
                    <TextField id='cm' type='number' value={selectedCircuit?.cenmando} contentEditable={false} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                </Box>
                <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignItems:'start'}} >
                    <Typography variant='subtitle1' fontWeight={'medium'} >Nom circuit</Typography>
                    <TextField id='circuitName' type='text' value={selectedCircuit?.circuitName} contentEditable={false} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                </Box>
                <DateInputComponent description="Data verificació visual" date={fvervis} handleDateChange={handleFvervisChange} />                
                <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignItems:'start'}} >
                    <Typography variant='subtitle1' fontWeight={'medium'} >Diferencial Mesura temps resposta</Typography>
                    <TextField id='responseMeasurement' type='number' value={responseMeasurement} onChange={(e) => setResponseMeasurement(parseFloat(e.target.value))} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                </Box>
                <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignItems:'start'}} >
                    <Typography variant='subtitle1' fontWeight={'medium'} >Diferencial Mesura sen</Typography>
                    <TextField id='measurementDiff' type='number' value={measurementDiff} onChange={(e) => setMeasurementDiff(parseFloat(e.target.value))} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                </Box>
               {/*<Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignItems:'start', zIndex: 1}} >
                    <Typography variant='subtitle1' fontWeight={'medium'} >Aïllament</Typography>
                    <TextField id='insulation' type='number' value={insulation} onChange={(e) => setInsulation(parseFloat(e.target.value))} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} inputProps={{ step: '0.01' }} />
                </Box>*/}
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Typography variant='subtitle1' fontWeight={'medium'}>Descripció</Typography>
                    <TextField id='descripcion' type='text' value={descriptioncircuitValue} onChange={(e) => setDescriptioncircuit(e.target.value)} sx={{ backgroundColor: 'white', marginLeft: '0 !important' }} />
                </Box>                       
                <Box>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Circuit actiu?</Typography>
                    
                        <ToggleButtonGroup
                            value={isActive}
                            exclusive
                            onChange={handleIsActive}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        >  <ToggleButton value={true} aria-label='notActive' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'Actiu'}</Typography>
                            </ToggleButton>
                            <ToggleButton value={false} aria-label='active' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'Inactiu'}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>                    
                </Box>
                <Box >
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Sortida monofàsica ?</Typography>
                    
                        <ToggleButtonGroup
                            value={isSormon}
                            exclusive
                            onChange={handleIsSormon}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        >  <ToggleButton value={true} aria-label='notActive' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'Actiu'}</Typography>
                            </ToggleButton>
                            <ToggleButton value={false} aria-label='active' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'Inactiu'}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>                    
                </Box>

                <Box >
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Sortida afectada per regulació?</Typography>
                    
                        <ToggleButtonGroup
                            value={isSortReg}
                            exclusive
                            onChange={handleIsSortReg}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        >  <ToggleButton value={true} aria-label='notActive' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'Actiu'}</Typography>
                            </ToggleButton>
                            <ToggleButton value={false} aria-label='active' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'Inactiu'}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>                    
                </Box>

                <Box sx={{mb: 2}}>
                    <Typography variant='subtitle1' sx={{ my: 0.2 }} fontWeight={'medium'} >Revisat</Typography>
                    
                        <ToggleButtonGroup
                            value={isInspected}
                            exclusive
                            onChange={handleIsInspected}
                            aria-label="status"
                            size='large'
                            sx={{
                                width: '100%',
                                display: 'flex',
                            }}
                        >  <ToggleButton value='N' aria-label='notInspected' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'No revisat'}</Typography>
                            </ToggleButton>
                            <ToggleButton value='Y' aria-label='inspected' css={toggleBtnsStyle}>
                                <Typography variant="subtitle1">{'Revisat'}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>                    
                </Box>
                {error && (
                    <ErrorBackdropComponent error={error} isOpen={isOpen} />
                )}
                {(isLoading || isFulfilled || isRejected) && (
                    <BackdropComponent isLoading={isLoading} isFulfilled={isFulfilled} isRejected={isRejected} isOpen={isOpen} />
                )} 
            </Container>
            <Box
                ref={fixedContainerRef}
                sx={{
                    width: '100%',
                    height: '7vh',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    zIndex: 9999,
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px -2px 15px',
                    marginTop: 'calc(2vw + 10px)',
                }}>
                    <Button onClick={handleCancelBtnClick} sx={{fontSize: '1.2rem', color: '#9e9e9e', }} >Cancel·lar</Button>
                    
                    <Button onClick={handleSaveClick} sx={{fontSize: '1.2rem', color:'palette.primary.main', }} >Guardar</Button>
                </Box>
        </Dialog>
    )     
}